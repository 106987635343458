<template>
  <div class="fenxiangbox">
    <Headers :title="$t('head.route7')"></Headers>
    <div class="qrbox pt-130px">
      <vue-qr
        class="child1"
        :margin="0"
        colorDark="#68b443"
        colorLight="#fff"
        :text="invite"
        :logoScale="0.3"
        :size="150"
      ></vue-qr>
    </div>
    <div class="box1">{{ $t("head.scanOrCopy") }}</div>
    <div class="box2">
      {{ this.invite }}
    </div>
    <div
      class="box3"
      v-clipboard="invite"
      v-clipboard:success="handleSuccess"
      v-clipboard:error="handleError"
    >
    {{ $t("head.copyLink") }}
      
    </div>
    <div class="box4">{{ $t("head.inviterAddress") }}</div>
    <div class="box5">
      {{ global.account }}
      <i
        class="el-icon-document-copy"
        v-clipboard="global.account"
        v-clipboard:success="handleSuccess"
        v-clipboard:error="handleError"
      ></i>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import mixin from "../mixins/index";
import Headers from "../components/headers.vue";
export default {
  data() {
    return {
      invite: "",
    };
  },
  mixins: [mixin],
  watch: {
    "global.account"() {
      console.log(window.location);
      this.invite = window.location.origin + "/#/?" + this.global.account;
    },
  },
  methods: {
    handleSuccess() {
      //成功
      this.$toast(this.$t("head.copySuccess"));
    },
    handleError() {
      //失败
      this.$toast(this.$t("head.copyFail"));
    },
  },
  components: {
    Headers,
    vueQr,
  },
};
</script>

<style lang="scss" scoped>
.fenxiangbox {
  text-align: center;
  font-family: PingFang SC;
  font-weight: bold;
  .qrbox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    .child1 {
      border: 5px solid #fff;
    }
  }
  .box1 {
    font-size: 18px;
    margin-bottom: 25px;
  }
  .box2 {
    font-size: 10px;
    margin-bottom: 20px;
  }
  .box3 {
    border-radius: 18px;
    height: 36px;
    line-height: 36px;
    @apply text-primary border border-primary;
    font-size: 15px;
    margin-bottom: 25px;
  }
  .box4 {
    font-size: 15px;
    margin-bottom: 18px;
  }
  .box5 {
    font-size: 13px;
  }
}
</style>